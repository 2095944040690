.main-component {
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #000000;
}

.login-component {
    height: 100vh;
    width: 100%;
    padding: 20px;
    background-image: url("https://storage.googleapis.com/ads-assets-ui/amagi_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.amagi-branding {
  height: 30px;
  padding-top: 10px;
}

.amagi-branding-logo {
  height: 20px;
}

.amagi-branding-div {
  padding: 30px 140px;
}

.amagi-branding-text {
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #ffffff;
}

.amagi-card-login {
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.amagi-login-branding {
  height: 30px !important;
}

.amagi-log-in-div {
  margin-top: 20px !important;
}

.amagi-sign-in-text {
  color: rgba(231, 231, 231, 1);
  font-size: 18px !important;
  font-weight: bolder !important;
}

.amagi-login-form-field {
  margin-top: 20px !important;
}

.white-form-fields {
  color: #ffffff !important;
  margin-top: 25px !important;
}

.white-form-fields:first-child {
  margin-top: 0px !important;
}

.white-form-fields .MuiFormLabel-root,
.white-form-fields .MuiInputLabel-root {
  color: rgba(134, 134, 134, 1) !important;
  font-size: 16px;
}

.white-form-fields .MuiInputBase-root:before,
.white-form-fields .MuiInput-root:before {
  border-color: rgba(134, 134, 134, 1) !important;
}

.white-form-fields .MuiSvgIcon-root {
  color: rgba(231, 231, 231, 1) !important;
}

.white-form-fields .MuiInputBase-input {
  color: rgba(134, 134, 134, 1) !important;
  font-size: 14px;
}

.white-form-fields .Mui-focused {
  border-color: rgba(134, 134, 134, 1) !important;
}

.white-form-fields .MuiOutlinedInput-root {
  border: 1px solid rgba(134, 134, 134, 1) !important;
}

.filled-submit-button {
  background-color: rgba(253, 165, 96, 1) !important;
  color: rgba(15, 15, 15, 1) !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  text-transform: none !important;
}

.filled-dropdown-arrow {
  color: white !important;
}

.flex-align-content {
  display: flex;
  align-items: center;
}

.flex-justify-content-start {
  justify-content: flex-start;
}

.flex-justify-content-end {
  justify-content: flex-end;
}

.flex-justify-content-center {
  justify-content: center;
}

.chart-icon-container {
  display: flex;
  padding: 10px 16px;
  padding-left: 0px;
  align-items: center;
  align-self: stretch;
}

.chart-icon-label {
  color: #e7e7e7;

  /* Body-Normal/Default */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
}

.chart-icon {
  width: 16px;
  height: 16px;
}

.spacing-bottom {
  margin-bottom: 16px;
}

.spacing-horizontal {
  margin: 0 2px;
}

.user-select-none {
  user-select: none;
}

.amagi-header {
  background-color: #1e1e1e;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.amagi-header-logo {
  height: 16px;
  margin-top: 8px;
}
.amagi-header-logo:hover {
  cursor: pointer;
}

.text-align-left {
  text-align: right;
}

.white-icon {
  color: #e7e7e7;
}
.white-icon.MuiAvatar-root {
  width: 34px;
  height: 32px;
}

.white-icon:hover {
  cursor: pointer;
}

.error-div {
  padding: 35px;
  text-align: center;
}

.white-text {
  color: #ffffff;
}

.error-button-div {
  margin-top: 30px !important;
}

.error-description-div {
  margin-top: 10px !important;
}

.amagi-content-div {
  width: 100%;
  height: 100%;
}

.amagi-content-header-div {
  height: 58px;
}

.amagi-content-body-div {
  height: calc(100% - 58px);
}

.amagi-body-div {
  padding: 20px;
}

.amagi-header-typography {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  color: white;
  margin-bottom: 10px !important;
}
.alert-typography {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: white;
}

.no-data-text {
  color: #fda560;
  margin: 14px 0;
}

.select-container {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.03);
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.select-selected-text {
    overflow: hidden;
    color: #E7E7E7;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.28px;
    height: 20px;
    flex: 1 0 0;
}

.menu {
  border-radius: 4px;
  background: #272727;
  box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.15),
    0px 0px 1px 0px rgba(0, 0, 0, 0.31);
}

.menu-container .MuiMenu-paper {
  background: none !important;
}

.selected-menu-item {
  color: white !important;
  border-left: #fda560 2px solid !important;
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.menu-item {
  color: white !important;
  border-left: transparent 2px solid !important;
}

.filter-view-data {
  width: 32.8%;
  height: 100px;
  background-color: #1a1a1a !important;
  padding: 20px;
  border-radius: 3px;
  text-align: center;
  margin-left: 10px !important;
  border: 1px solid hsl(260deg 11% 95% / 29%);
}

@media (min-width: 1561px) {
  .filter-view-data {
    width: 32.8% !important;
  }
}

@media (max-width: 1261px) {
  .filter-view-data {
    width: 49%;
    margin-bottom: 10px !important;
  }

  .filter-view-data:nth-child(3) {
    margin-left: 0px !important;
  }
}

.filter-view-data:first-child {
  margin-left: 0px !important;
}

.alert-header {
  font-size: 16px !important;
  color: #ffffff;
  font-weight: 700 !important;
}

.alert-count-data {
  font-size: 24px !important;
  font-weight: 800 !important;
  color: #e7e7e7;
}

.listing-container {
  margin-top: 15px;
  border: 1px solid hsl(260deg 11% 95% / 29%);
  border-radius: 3px;
}
.listing-container-users .MuiGrid-root {
  height: 100%;
}

.listing-filter-container {
  border-bottom: 1px solid hsl(260deg 11% 95% / 29%);
  background: #1e1e1e;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.off-white-text {
  color: #e7e7e7 !important;
  font-size: 14px !important;
  padding: 8px 0px;
}

.off-white-text span {
  font-weight: 600 !important;
}

.listing-table-container {
  width: 100%;
  height: 300px;
  min-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (min-width: 1261px) {
  .listing-table-container {
    height: 540px;
  }
}

@media (min-width: 1561px) {
  .listing-table-container {
    height: 630px;
  }
}

@media (max-width: 1261px) {
  .listing-table-container {
    height: 500px;
  }
}

@media (min-width: 1600px) {
  .listing-table-container {
    height: 800px;
  }
}

.white-table {
  width: 100%;
}

.white-table .MuiTableHead-root {
  background-color: #1a1a1a;
}

.white-table .MuiTableHead-root .MuiTableCell-root {
  color: #868686 !important;
  font-weight: 600;
  font-size: 14px !important;
}

.white-table .MuiTableCell-root {
  border-bottom-color: hsl(260deg 11% 95% / 29%);
  color: #ffffff;
  font-size: 14px !important;
}

.white-table .MuiTableRow-root:last-child .MuiTableCell-root {
  border-bottom: 0px !important;
}

.error-alerting {
  color: #ff6961 !important;
}

.spot-icon-ui {
  color: #3de1b1;
}

.demand-tag-span {
  padding: 8px;
  border-radius: 17px;
  background-color: #1a1a1a;
  color: #868686;
  margin-right: 5px;
}

.spot-span {
  position: relative;
  top: -5px;
}

.text-primary {
  color: #7fb8f0 !important;
  display: block;
  font-weight: bold;
}

.text-primary:hover {
  cursor: pointer;
}

.text-description {
  color: #868686 !important;
  font-size: 12px;
  display: block;
}

.details-drawer-content .MuiDrawer-paper {
  width: 60vw;
  height: 100vh;
  background: #1a1a1a;
}

.details-drawer-content .details-header-drawer {
  padding: 20px;
  font-size: 20px;
  color: #e7e7e7;
  font-weight: bolder;
  border-bottom: 1px solid hsl(260deg 11% 95% / 29%);
  height: 65px;
}

.filter-drawer-content .MuiDrawer-paper {
  width: 40%;
  height: 100vh;
  background: #1a1a1a;
}

.filter-drawer-content .MuiTypography-root{
  font-weight: 800 !important;
}

.drawer-close-icon {
  text-align: right;
}

.details-drawer-content .MuiSvgIcon-root:hover {
  cursor: pointer;
}

.details-drawer-content .details-content-drawer {
  padding: 20px;
  font-size: 16px;
  color: #e7e7e7;
  min-height: calc(100vh - 137px);
  height: auto;
  max-height: 300vh;
  overflow-y: auto;
}

.details-content-drawer .details-content-row {
  margin-bottom: 15px;
}

.details-key-details {
  color: #868686;
  font-weight: 600;
}

.copy-icon {
  color: hsla(240, 7%, 62%, 1);
  font-size: 16px !important;
  font-weight: 800;
  position: relative;
  top: 2px;
}

.details-drawer-content .details-footer-drawer {
  padding: 20px;
  font-size: 20px;
  color: #e7e7e7;
  font-weight: bolder;
  border-top: 1px solid hsl(260deg 11% 95% / 29%);
  height: 72px;
}

.black-outlined-button {
  border-color: rgba(255, 255, 255, 0.08) !important;
  color: #e7e7e7 !important;
  font-weight: bolder !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
}

.black-outlined-button-ignored {
  border-color: #fc6203 !important;
  color: #fc6203 !important;
  font-weight: bolder !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
}

.black-filled-button {
  background-color: rgba(255, 255, 255, 0.08) !important;
  border-color: rgb(123 123 123 / 35%) !important;
  color: #e7e7e7 !important;
  font-weight: bolder !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
}

.white-table-details {
  width: 100%;
}

.white-table-details .MuiTableHead-root {
  background-color: #fafafa17;
}

.white-table-details .MuiTableHead-root .MuiTableCell-root {
  color: #868686 !important;
  font-weight: 600;
  font-size: 14px;
}

.white-table-details .MuiTableCell-root {
  border-bottom-color: #fafafa17 !important;
  color: #ffffff;
  font-size: 15px;
}

.details-list-item {
  padding: 0px !important;
}

.details-list-item .MuiListItem-root {
  padding: 0px !important;
}

.list-details-demand-partner {
  padding: 0px !important;
}

.list-details-demand-partner .MuiListItem-root {
  padding: 0px !important;
  border-bottom: 1px solid #fafafa17;
}

.list-details-demand-partner .MuiListItem-root:only-child {
  border-bottom: none !important;
}

.list-details-demand-partner .MuiListItem-root:last-child {
  border-bottom: none !important;
}

.filter-data-skeleton {
  width: 100% !important;
  height: 100px !important;
  border-radius: 3px !important;
  background-color: #fafafa3b !important;
  margin-bottom: 20px;
}

.menu-items .MuiList-root {
  display: flex;
  background: #1e1e1e !important;
  color: #ffffff;
  width: 208px;
  border: 1px solid #ffffff33;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  box-shadow: 0px 18px 28px 0px rgba(0, 0, 0, 0.15),
    0px 0px 1px 0px rgba(0, 0, 0, 0.31);
  border-radius: 4px;
}

.menu-items .MuiSvgIcon-root {
  color: #ffffff;
}

.show-password-icon:hover {
  cursor: pointer;
}

.text-align-center {
  text-align: center;
}

.healthy-header {
  margin: 0px;
  color: #ffffff;
  padding: 20px;
}

.ignored-row .MuiTableCell-root {
  background-color: #42424296;
}

.data-row {
  background-color: #1e1e1e;
}

.sort-icon {
  position: relative;
  top: 7px;
  margin: -2px;
  left: 4px;
}

.sort-icon:hover {
  cursor: pointer;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotate 5s linear infinite;
  position: absolute;
  margin-left: 9px;
  margin-top: -2px;
}

.loading-icon {
  animation: rotate 5s linear infinite;
  position: absolute;
  margin: 13px;
  color: #ffffff !important;
  font-size: 40px !important;
  margin-top: 20px;
}

.alert-group-tag {
  padding: 7px;
  background: #3a3a3a;
  border-radius: 16px;
}

.text-cammel-case {
  text-transform: capitalize;
}

.tabsRoot {
  background-color: #000000 !important;
  color: #ffffff !important;
  width: 300px;
  font-weight: bold !important;
  border: 1px solid #ffffff33 !important;
}

.tabsRoot .MuiTabs-indicator {
  background-color: #fda560 !important;
}

.tabRoot {
  text-transform: initial;
  color: white !important;
}

.tabHover:hover {
  color: green;
}

.tabSelected {
  color: white !important;
  background-color: rgba(255, 255, 255, 0.08) !important;
}

.tabFocus:focus {
  color: purple;
}

.tabPanel {
  margin-bottom: 8px;
}

.tabPanelContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.checkbox{
  height: auto;
}

.checkbox .MuiSvgIcon-root{
  color: #FFFFFF33 !important;
  font-size: 17px;
}

.checkbox.Mui-checked {
  color: #fda560 !important;
}

.checkbox .MuiTypography-root{
  color: #E7E7E7 !important;
  font-size: 14px !important;
  text-transform: capitalize;
}

.filter-list-container {
  height: 188px;
  overflow-y: scroll;
}

.filter-list-options {
  margin: 2px;
}

.filter-list-option-label-selected {
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
}

.filter-list-option-label {
  color: rgba(245, 245, 245, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
}

.reset-button {
  color: #7fb8f0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
  cursor: pointer;
}

.generic-dropdown-container {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.03);
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  cursor: pointer;
  justify-content: space-between;
}

.generic-dropdown-selected-option-label {
  overflow: hidden;
  color: #616161;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
}

.generic-dropdown-arrow {
  color: #e7e7e7;
}

.filter-accordion-container {
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.05) !important;
}

.filter-accordion-summary {
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.05) !important;
}

.filter-accordion-summary .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}

.filter-accordion-summary.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
}

.filter-accordion-summary .MuiAccordionSummary-expandIconWrapper {
  color: white !important;
}

.filter-accordion-summary-label {
  color: #e7e7e7;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
}

.filter-accordion-details {
  display: flex;
  padding: 16px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background-color: #1e1e1e !important;
}

.generic-icon-container {
  display: flex;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.generic-container-actionbar {
  display: flex;
  gap: 4px;
}

.generic-search-container .MuiInputBase-input {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  color: white !important;
  font-size: 14px;
}

.generic-search-container {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  background: rgba(255, 255, 255, 0.03) !important;
  display: flex;
}

.generic-search-container .MuiOutlinedInput-notchedOutline{
  border-color: transparent !important;
}

.table-action-bar-top {
  display: flex;
  justify-content: space-between;
}

.no-data-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #868686;
}

.no-data-found-container-subtext {
  color: #868686;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.28px;
}

.no-data-found-container-header {
  color: #e7e7e7;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 0px;
}

.white-lined {
  height: 1px;
  padding: 0px !important;
  margin: 0px !important;
  background-color: rgba(255, 255, 255, 0.08);
  margin-bottom: 16px !important;
}

.blur-text {
  color: transparent !important;
  text-shadow: 0 0 8px #000;
}
/* userManagement css */
.create-button-grid {
  text-align: right;
}
.create-button {
  background-color: rgba(253, 165, 96, 1) !important;
  color: rgba(15, 15, 15, 1) !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  text-transform: none !important;
  margin-right: 20px;
}
/* .delete-popover {
    
} */
.custom-popover {
  color: white;
}

.custom-popover .MuiPaper-root {
  background-color: #1a1a1a;
  color: white;
  height: 88px;
  width: 240px;
}
.custom-popover .MuiPopover-paper {
  width: 300px;
}
.custom-popover .clickable-grid-popover:hover {
  cursor: pointer;
  background-color: hsl(260deg 11% 95% / 29%);
  height: 36px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
}
.custom-popover .clickable-grid-popover {
  align-items: center;
  height: 36px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
}
.sidenav-add-user .MuiPaper-root.MuiDrawer-paper {
  background-color: #1a1a1a;
  color: white;
}
.divider-user-management.MuiDivider-root {
  border-color: hsl(260deg 11% 95% / 29%);
}
.sidenav-add-user .MuiPaper-root.MuiDrawer-paper {
  color: #868686;
  font-weight: 600;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.text-field-add-user .MuiFormLabel-root.MuiInputLabel-root {
  color: #868686;
  font-size: 13px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.text-field-add-user .MuiInputBase-root.MuiInput-root:after {
  border-bottom: 1px solid #868686;
}
.text-field-add-user .MuiInputBase-root.MuiInput-root {
  border-bottom: 1px solid #868686;
}
.text-field-add-user.MuiFormControl-root.MuiTextField-root {
  margin-top: 0px;

  width: 60%;
  margin-left: 75px;
}
/* .text-field-add-user .MuiInputBase-root.MuiInput-root {
  margin-top: 0px;
} */
.text-field-add-user .MuiInputBase-root.MuiInput-root {
  color: white;
  font-size: 13px;
}

.cancel-button {
  background-color: #868686 !important;
  color: white;
  font-weight: 600 !important;
  margin-top: 20px !important;
  text-transform: none !important;
  margin-right: 20px;
}
.cancel-button-del {
  background-color: #868686 !important;
  color: white;
  font-weight: 600 !important;
  margin-top: 5px !important;
  text-transform: none !important;
  margin-right: 20px;
}
.create-button-del {
  background-color: rgba(253, 165, 96, 1) !important;
  color: rgba(15, 15, 15, 1) !important;
  font-weight: 600 !important;
  margin-top: 5px !important;
  text-transform: none !important;
  margin-right: 20px;
}

.del-dialog .MuiPaper-root.MuiDialog-paper {
  background-color: #1e1e1e;
  color: white;
  height: 300px;
  width: 600px;
}
.MuiDialogContent-root.dialog-divider {
  border-top: 0.8px solid #868686;
  border-bottom: 0.8px solid #868686;
}

.user-del-snack .MuiPaper-root.MuiAlert-root {
  background-color: white;
}

.user-checkbox.MuiButtonBase-root.MuiCheckbox-root {
  color: #868686;
}
.add-icon.MuiSvgIcon-root {
  height: 20px;
  width: 20px;
  margin-top: 1px;
}
.create-user-button {
  background-color: rgba(253, 165, 96, 1) !important;
  color: rgba(15, 15, 15, 1) !important;
  font-weight: 600 !important;
  margin-top: 20px !important;
  text-transform: none !important;
  margin-right: 16px;
  padding: 6px 12px;
  display: flex;
  gap: 8px;
  height: 32px;
  width: 111px;
}
.generic-user-container-actionbar {
  display: flex;
  gap: 4px;
  height: 32px;
  width: 348px;
  padding-left: 12px;
}
/* .generic-user-search-container .MuiInputBase-root.MuiOutlinedInput-root {
  padding-left: 12px;
} */
.sidebar-mid {
  height: calc(100vh - 140px);
}
.username-avatar.MuiAvatar-root {
  color: #e7e7e7;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.24px;
  height: 28px;
  width: 28px;
  background-color: #ffffff14;
}
.user-name {
  margin-left: 8px;
  margin-top: 4px;
}
.keydown-icon.MuiSvgIcon-root {
  height: 16px;
  width: 16px;
  margin-top: 4px;
}
.user-active.MuiSvgIcon-root {
  height: 16px;
  width: 16px;
  margin-right: 8px;
  margin-top: 2px;
}
.add-user-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.user-management-icon {
  margin-right: 16px;
  margin-top: 8px;
}
.user-management-icon:hover {
  cursor: pointer;
}
.user-management-icon.MuiSvgIcon-root {
  color: white;
  height: 16px;
  width: 16px;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #272727 !important;
  color: white !important;
}
.pop-typography.MuiTypography-root {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  margin-left: 8px;
}
.vpnkey-icon {
  width: 16px;
  height: 16px;
}
.user-detail-popover .MuiPaper-root.MuiPopover-paper {
  display: flex;
  width: 208px;
  padding: 24px;
  height: auto;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background: #1e1e1e;
  box-shadow: 0px 18px 28px 0px rgba(0, 0, 0, 0.15),
    0px 0px 1px 0px rgba(0, 0, 0, 0.31);
  color: white;
}
.user-avatar-dropdown.MuiAvatar-root {
  height: 48px;
  width: 48px;
}
.user-name-typography.MuiTypography-root {
  color: #e7e7e7;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
  margin-top: 24px;
}

.user-role-typography.MuiTypography-root {
  color: #999;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
  margin-top: 8px;
}
.user-email-typography.MuiTypography-root {
  color: #999;
  text-align: center;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.user-view-profile-typography.MuiTypography-root {
  color: #7fb8f0;
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.user-profile-divider.MuiDivider-root {
  width: 181.003px;
  height: 1px;
  background: rgba(255, 255, 255, 0.08);
  margin-top: 24px;
  margin-bottom: 24px;
}
.logout-btn.MuiButtonBase-root.MuiButton-root {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
}
.user-profile-dialog .MuiPaper-root.MuiDialog-paper {
  background-color: #0f0f0f;
  color: white;
  max-width: 600px;
  width: 100%;
}
.user-profile-dialog-title.MuiTypography-root.MuiDialogTitle-root {
  color: #e7e7e7;

  /* Headings/H2 */
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
}
.user-profile-dialog-title {
  display: flex;
  padding: 16px 24px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  background: #1e1e1e;
}
.user-profile-dialog-content {
  display: flex;
  align-items: flex-start;
  gap: 36px;
  flex: 1 0 0;
  padding-top: 24px;
}

.profile-feature {
  display: flex;
  width: 150px;
  padding: 4px 0px;
  align-items: center;
  align-content: center;
  gap: 4px;
  flex-shrink: 0;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.profile-feature-typography.MuiTypography-root {
  color: #999;

  /* Body-Bold/Default */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}
.profile-feature-value-typography.MuiTypography-root {
  overflow: hidden;
  color: #e7e7e7;
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Body-Normal/Default */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.28px;
}

.user-view-profile-typography:hover {
  cursor: pointer;
}

.pagination-table-container {
  padding: 8px 0px;
  background: #1e1e1e;
  border-top: 1px solid #ffffff14;
}

.pagination-table-container .MuiPagination-ul button,
.pagination-table-container .MuiPagination-ul div {
  color: #868686;
}

.pagination-table-container .MuiPagination-ul .Mui-selected {
  background-color: rgb(178 177 177 / 8%) !important;
}

.pagination-table-load-more-button {
  background-color: #3a3a3a !important;
  color: white !important;
}

.amg-accordion{
  width: 99%;
  background-color: #252525 !important;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}

.amg-accordion:last-child{
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.amg-accordion .MuiAccordionSummary-content{
  color: #E7E7E7 !important;
  font-weight: 600;
}

.amg-accordion .MuiSvgIcon-root{
  color: #E7E7E7 !important;
}

.amg-accordion .MuiAccordionDetails-root{
  color: #E7E7E7 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.selected-alerts{
  font-size: 14px !important;
  color: #999999;
  text-align: center;
  background-color: #FFFFFF0D;
  padding: 5px;
  border-radius: 3px;
  margin-bottom: 5px !important;
}

.menu-item-dropdown{
  width: 100%;
  height: auto;
  position: relative;
  top: -6px;
}
